//this will contain the class which is a page control container for consistent design
// eslint-disable-next-line import/named
import { awaitableTemplate } from './template-processor';
import { customElement, property, state } from 'lit/decorators.js';
import { DataEntryOwner } from './DataEntryOwner';
import { DevelopmentError } from '../development-error';
import { disableUI, enableUI } from '../ui-lock';
import { EAbort } from '../abort';
import { EventBooleanAsync, EventSnippet, EventTemplate, EventVoidAsync } from '../../interop/webmodule-interop';
import { getInternalId } from './databinding/databinding';
import { html, PropertyValueMap, TemplateResult } from 'lit';
import { showError } from './show-error';
import { ViewBase } from './view-base';
import { when } from 'lit/directives/when.js';

//used to return the current active content of a page
export type PageElementProvider =
  | ((pageIndex: number) => Promise<HTMLElement>)
  | ((pageIndex: number) => HTMLElement)
  | ((pageIndex: number) => TemplateResult)
  | ((pageIndex: number) => Promise<TemplateResult>);
//an event triggered before changing to a page, which returns true if the page change is allowed
//asynchronous to allow conditional testing that might take time
export type PageConfirmationEvent = (pageIndex: number, page: PageManager) => Promise<boolean>;
export type PageEnteredEvent = (pageIndex: number, page: PageManager) => Promise<void>;
export type PageDeleteEvent = (pageIndex: number, page: PageManager) => Promise<void>;
export type PageAfterEnteredEvent = (pageIndex: number, page: PageManager) => Promise<void>;

export type MenuIconEvent = () => Promise<boolean>;

export interface MenuIconAction {
  caption?: EventSnippet;
  disabled?: boolean;
  event?: MenuIconEvent;
}

export type MenuIconEventList = MenuIconAction[];

export interface MenuIconOption extends MenuIconAction {
  classList?: string;
  childEvents?: MenuIconEventList;
}

export interface PageManager {
  //the content provider will execute after successful pageChanging, and before pageChange
  //if the content is immutable, then it is up to the provider to ensure this.
  //the content manager will check if the existing content matches (by ref) the new one
  //and will swap out if nesessary
  content: PageElementProvider;
  caption: EventSnippet;
  pageFragment?: string;
  hasDelete?: () => boolean;
  onEnter?: PageEnteredEvent;
  onAfterEnter?: PageAfterEnteredEvent;
  canLeave?: PageConfirmationEvent;
  canClose?: PageConfirmationEvent;
  onDelete?: PageDeleteEvent;
  buttonMenu?: EventSnippet;
  dispose?: PageEnteredEvent;
  data: any | null;
  callbacks?: PageCallback;
  pageButtonLocation?: PageButtonLocation;
}

export interface PageControlDeleteTabEventData {
  deletedPage: PageManager;
  deletedIndex: number;
  newIndex: number;
}

interface PageControlEvents {
  onDeleteTab?: (options: PageControlDeleteTabEventData) => Promise<void> | void;
}

export interface PageControlOptions {
  //build and return a list of pages
  pageInitializer?: () => PageManager[];
  defaultTabIndex?: number;
  plusPage?: MenuIconOption;
  menuIcons?: MenuIconOption[];
  events?: PageControlEvents;
}

export interface PageCallback {
  setDataEntryOwner?: (owner: DataEntryOwner) => void;

  setNeedsRefreshEvent(event: EventVoidAsync);
}

class PageDataEntryOwner implements DataEntryOwner {
  doForceClose: EventBooleanAsync;
  doTryClose: EventBooleanAsync;

  constructor(doTryClose: EventBooleanAsync, doForceClose: EventBooleanAsync) {
    this.doTryClose = doTryClose;
    this.doForceClose = doForceClose;
  }

  async tryClose(): Promise<boolean> {
    return await this.doTryClose();
  }

  async forceClose(): Promise<boolean> {
    return await this.doForceClose();
  }
}

export enum PageButtonLocation {
  left = 0,
  right = 1
}

@customElement('wm-pagecontrol')
export class PageControl extends ViewBase {
  @property({ type: Array })
  pages: PageManager[];

  elementId: string;
  protected events: PageControlEvents | null;
  private _firstTabLoaded: boolean = false;
  private plusPage?: MenuIconOption;
  @state()
  private menuIcons?: MenuIconOption[];
  @state()
  private tabReadonly = 0;

  constructor(options: PageControlOptions) {
    super();
    //TODO - maybe we want to inject an API.. but i dont think we need to
    this.elementId = `pagecontrol-${getInternalId()}`;
    this.events = options.events ?? null;
    //extend this array as needed and adjust each filter
    this.pages = options.pageInitializer?.() ?? [];
    this.pages.forEach(page => {
      this.doPageCallbacks(page);
    });
    this.plusPage = options.plusPage;
    this.menuIcons = options.menuIcons;
    this._activeTabIndex = -1;
    const firstTabIndex = options.defaultTabIndex ?? 0;
    if (firstTabIndex >= 0 && firstTabIndex < this.pageCount) this._activeTabIndex = firstTabIndex;
  }
  @state()
  private _activeTabIndex: number;

  @state()
  private _pageButtonLocation: PageButtonLocation = PageButtonLocation.left;

  public get pageButtonLocation(): PageButtonLocation {
    return this._pageButtonLocation;
  }

  public set pageButtonLocation(value: PageButtonLocation) {
    if (value != this._pageButtonLocation) {
      this._pageButtonLocation = value;
    }
  }

  get activeTabIndex(): number {
    return this._activeTabIndex;
  }

  get activePage(): PageManager | null {
    if (this._activeTabIndex >= 0 && this._activeTabIndex < this.pageCount) return this.pages[this._activeTabIndex];
    else return null;
  }

  get pageCount(): number {
    return this.pages.length;
  }

  @state()
  private _hideSingleTab = false;

  public get hideSingleTab() {
    return this._hideSingleTab;
  }

  public set hideSingleTab(value) {
    if (value !== this._hideSingleTab) {
      this._hideSingleTab = value;
    }
  }

  private get uiLocked(): boolean {
    return this.tabReadonly > 0;
  }

  async runOnEnterEvent() {
    await this.activePage?.onEnter?.(this._activeTabIndex, this.activePage);
  }

  async runOnAfterEnterEvent() {
    await this.activePage?.onAfterEnter?.(this._activeTabIndex, this.activePage);
  }

  async dispose(): Promise<void> {
    for (let i = 0; i < this.pageCount - 1; i++) {
      await this.pages[i].dispose?.(i, this.pages[i]);
    }
    this.pages = [];
    super.dispose();
  }

  addPage(page: PageManager, switchToPage = true) {
    this.pages = [...this.pages, page];
    if (switchToPage) {
      this.setActiveTabIndex(this.pageCount - 1);
    }
    this.doPageCallbacks(page);
  }

  public async closePage(page: PageManager) {
    const pageIndex = this.pages.indexOf(page);
    if (pageIndex >= 0) {
      return await this.deleteTab(pageIndex);
    }
    return false;
  }

  async setMenuIcons(menuIcons: MenuIconOption[]) {
    this.menuIcons = menuIcons;
  }

  async setActivePage(page: PageManager) {
    const idx = this.pages.findIndex(p => p === page);
    if (idx === -1) throw new Error(`invalid page object passed to setActivePage`);
    await this.setActiveTabIndex(idx);
  }

  async applyWindowHash() {
    await this.setActiveTabByHash(window.location.hash);
  }

  async setActiveTabByHash(hashId: string) {
    if (hashId.at(0) == '#') {
      hashId = hashId.substring(1);
    }
    const idx = this.pages.findIndex(p => p.pageFragment === hashId);
    if (idx === -1) return;

    await this.setActiveTabIndex(idx);
  }

  async setActiveTabIndex(value: number, force?: boolean): Promise<void> {
    this.pageRangeTest(value, true);
    const currentIndex = this._activeTabIndex;
    if (value !== this._activeTabIndex || force) {
      await this.lockUI(true);
      try {
        const canLeave = !this._firstTabLoaded
          ? true
          : value === this._activeTabIndex || (await this.canLeavePage(this._activeTabIndex, this.activePage));
        if (canLeave) {
          this._activeTabIndex = value;
          try {
            await this.activePage?.onEnter?.(this.activeTabIndex, this.activePage);
            if (this.activePage?.pageFragment) {
              // use history.pushState instead of window.location.hash as the latter reloads the page, page is already loaded
              const route = window.location.pathname;
              history.pushState(null, '', `${route}#${this.activePage.pageFragment}`);
            }

            await this.activePage?.onAfterEnter?.(this._activeTabIndex, this.activePage);
          } catch (e) {
            if (e instanceof EAbort) {
              this._activeTabIndex = currentIndex;
            } else throw e;
          }
        }
      } finally {
        await this.lockUI(false);
      }
    }
  }

  template(): EventTemplate {
    const tabTemplate =
      this.pageCount == 1 && this.hideSingleTab
        ? html``
        : html` <ul class="nav nav-tabs nav-fill page-control-tabs" role="tablist">
            ${this.tabTitleTemplate()}
          </ul>`;
    return html` <div id=${this.elementId} class="page-control">
      <div class="page-control-container">
        ${tabTemplate}
        <div class="tab-content page-control-tab-content">${this.tabContentTemplate()}</div>
        <div class="page-control-footer">
          <ul class="nav nav-tabs nav-fill page-control-tabs" role="tablist">
            ${this.tabFooterTemplate()}
          </ul>
        </div>
      </div>
    </div>`;
  }

  tabContentTemplate(): TemplateResult[] {
    const count = this.pageCount;
    const result: TemplateResult[] = [];
    for (let i = 0; i < count; i++) {
      const tabid = `"item${i}-tab"`;
      const contentid = `itemtab-${i}`;
      const classes = `tab-pane ${this.activeTabIndex === i ? 'show active' : ''}`;

      const template = html` <div class=${classes} id=${contentid} role="tabpanel" aria-labelledby=${tabid}>
        ${awaitableTemplate(this.pages[i].content(i))}
      </div>`;
      result.push(template);
    }
    return result;
  }

  tabFooterTemplate(): TemplateResult[] {
    const result: TemplateResult[] = [];
    if (this.plusPage || this.menuIcons) {
      const plusPageEvent = async (e: Event) => {
        e.stopPropagation();
        e.preventDefault();
        if (this.plusPage?.disabled || this.uiLocked) return;
        await this.lockUI(true);
        try {
          await this.runPlusPage();
        } finally {
          await this.lockUI(false);
        }
      };
      const specificIconEvent = icon => {
        return async (e: Event) => {
          e.stopPropagation();
          e.preventDefault();
          if (icon.disabled || this.uiLocked) return;
          icon.disabled = true;
          await this.lockUI(true);
          try {
            if (!Array.isArray(icon.event)) await icon.event?.();
          } finally {
            icon.disabled = false;
            await this.lockUI(false);
          }
        };
      };

      const buttonMenuTemplate = (icon: MenuIconOption): TemplateResult => {
        const classList = icon.classList ?? '';

        if (icon.event && !icon.childEvents) {
          return topLevelButtonTemplate(icon);
        } else if (icon.childEvents) {
          const buttonInner = html`
            <webmodule-button size="small" variant="primary" class="${classList}" slot="trigger" caret caretup>
              ${when(!icon.event, () => html`${icon.caption?.()}`)}
            </webmodule-button>
          `;

          const subButtons: TemplateResult[] = [];
          icon.childEvents?.forEach(child => subButtons.push(subLevelMenuTemplate(child)));

          const buttonTemp = html` <webmodule-dropdown placement="top" distance="5">
            ${buttonInner}
            <webmodule-menu> ${subButtons} </webmodule-menu>
          </webmodule-dropdown>`;

          return html`${!icon.event
            ? html`${buttonTemp}`
            : html`
                <webmodule-button-group label="Page actions">
                  ${topLevelButtonTemplate(icon)} ${buttonTemp}
                </webmodule-button-group>
              `} `;
        }

        return html``;
      };

      const topLevelButtonTemplate = (icon: MenuIconOption): TemplateResult => {
        const classList = icon.classList ?? '';
        return html` <webmodule-button
          size="small"
          variant="primary"
          class="${classList}"
          @click=${specificIconEvent(icon)}
          ?disabled=${icon?.disabled || this.uiLocked}
        >
          ${icon.caption?.()}
        </webmodule-button>`;
      };

      const subLevelMenuTemplate = (icon: MenuIconOption): TemplateResult => {
        return html`
          <webmodule-menu-item @click=${specificIconEvent(icon)} ?disabled=${icon?.disabled || this.uiLocked}>
            ${icon.caption?.()}
          </webmodule-menu-item>
        `;
      };

      const buttons: TemplateResult[] = [];

      if (this.plusPage) {
        if (this.plusPage.childEvents) {
          const subButtons: TemplateResult[] = [];

          this.plusPage.childEvents?.forEach(icon => subButtons.push(subLevelMenuTemplate(icon)));

          buttons.push(
            html` <webmodule-dropdown placement="top-start" distance="5">
              <webmodule-button size="small" variant="primary" slot="trigger" id=${this.elementId + '-plus'} caret>
                ${this.plusPage?.caption?.()}
              </webmodule-button>
              <webmodule-menu> ${subButtons} </webmodule-menu>
            </webmodule-dropdown>`
          );
        } else
          buttons.push(
            html` <webmodule-button
              size="small"
              variant="primary"
              @click=${plusPageEvent}
              ?disabled=${this.plusPage?.disabled}
            >
              ${this.plusPage?.caption?.()}
            </webmodule-button>`
          );
      }

      const pageButtons = awaitableTemplate(this.activePage?.buttonMenu?.());

      /*Display the button menu either on the left or right based on the settings in either PageManager or PageControl
       * The default on PageControl is PageButtonLocation.left, if either the PageManager or PageControl sets the value
       * to the right, it will render the buttons as part the primary buttons on right hand side.
       * Setting the value on the PageControl means it is set for all pages (PageManager) part of the control
       * Setting it on the PageManager means it is an override only for that single page. */
      const buttonMenuLocation = this.activePage?.pageButtonLocation ?? this.pageButtonLocation;

      this.menuIcons?.forEach(icon => buttons.push(buttonMenuTemplate(icon)));

      const buttonsTemplate =
        buttonMenuLocation == PageButtonLocation.right
          ? html` <div class="page-nav-buttons-left"></div>
              <div class="page-nav-buttons-right">${pageButtons}${buttons}</div>`
          : html` <div class="page-nav-buttons-left">${pageButtons}</div>
              <div class="page-nav-buttons-right">${buttons}</div>`;
      const template = html` <li class="nav-item page-nav-buttons" role="presentation">${buttonsTemplate}</li>`;
      result.push(template);
    }
    return result;
  }

  tabTitleTemplate(): TemplateResult[] {
    //dynamically build the list of tabs for the items in memory
    const count = this.pageCount;

    const result: TemplateResult[] = [];

    for (let i = 0; i < count; i++) {
      const pageManager = this.pages[i];
      const id = `"item${i}-tab"`;
      const contentid = `itemtab-${i}`;
      const classes = `nav-link ${
        !this.uiLocked && this.activeTabIndex === i ? 'active' : ''
      } ${this.uiLocked ? ' disabled ' : ''}`;

      const clickEvent = async (e: Event) => {
        e.stopPropagation();
        e.preventDefault();
        if (this.uiLocked) return;
        await this.setActiveTabIndex(i);
      };
      const deleteEvent = async (e: Event) => {
        e.stopPropagation();
        e.preventDefault();
        if (this.uiLocked) return;
        await this.deleteTab(i);
      };
      const deleteTemplate =
        (pageManager.hasDelete?.() ?? false)
          ? html` <webmodule-icon-button
              library="default"
              name="x-lg"
              @click=${deleteEvent}
              aria-label="Close"
              class="close-button"
            ></webmodule-icon-button>`
          : html``;
      const caption = pageManager.caption?.() ?? '?';

      // eslint-disable-next-line lit-a11y/anchor-is-valid
      const template = html` <li class="nav-item" role="presentation">
        <a
          class=${classes}
          id=${id}
          type="button"
          role="tab"
          aria-controls=${contentid}
          aria-selected="true"
          @click=${clickEvent}
        >
          ${caption} ${deleteTemplate}
        </a>
      </li>`;
      result.push(template);
    }

    return result;
  }

  async runPlusPage(): Promise<void> {
    if (this.plusPage?.disabled) return;
    if (this.plusPage && (await this.canLeavePage(this.activeTabIndex, this.activePage))) {
      this.plusPage.disabled = true;
      try {
        this.requestUpdate();
        if (!Array.isArray(this.plusPage.event)) await this.plusPage.event?.();
      } finally {
        this.plusPage.disabled = false;
        this.requestUpdate();
      }
    }
  }

  public async clear() {
    while (this.pageCount > 0) {
      await this.deleteTab(0);
    }
  }

  public async canCloseAllTabs(): Promise<boolean> {
    let result = true;
    for (let i = 0; i < this.pageCount; i++) {
      const page = this.pages[i];
      if (page.hasDelete?.() ?? false) {
        if (!(await page.canClose?.(i, page))) result = false;
      } else if (page === this.activePage) {
        if (!(await page.canLeave?.(i, page))) result = false;
      }
    }
    return result;
  }

  protected async firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): Promise<void> {
    try {
      await this.activePage?.onEnter?.(this._activeTabIndex, this.activePage);
      this.requestUpdate();
      await this.updateComplete;
      await this.activePage?.onAfterEnter?.(this._activeTabIndex, this.activePage);
      this._firstTabLoaded = true;
    } catch (e) {
      await showError(e as Error);
    }
  }

  protected doPageCallbacks(page: PageManager) {
    if (page.callbacks) {
      page.callbacks.setNeedsRefreshEvent(async () => this.requestUpdate());
      page.callbacks.setDataEntryOwner?.(
        new PageDataEntryOwner(
          async () => await this.closePage(page),
          async () => await this.closePage(page)
        )
      );
    }
  }

  private async lockUI(lock: boolean) {
    if (lock) {
      disableUI();
      this.tabReadonly++;
    } else {
      enableUI();
      this.tabReadonly--;
    }
    if (this.tabReadonly < 0) throw new DevelopmentError('Page Control Readonly Lock out of sync');
  }

  private async canLeavePage(index: number, page: PageManager | null): Promise<boolean> {
    return page == null || (page.canLeave ? await page.canLeave?.(index, page) : true);
  }

  private pageRangeTest(value: number, allowNegOne?: boolean) {
    if ((allowNegOne && value === -1) || (value >= 0 && value < this.pageCount)) return;
    throw new Error(`Page index ${value} out of range [0,${this.pages.length - 1}]`);
  }

  private async deleteTab(i: number): Promise<boolean> {
    const reValidatePage = async () => {
      //there may be no pages left, so test to make sure the index is valid
      if (this.activeTabIndex >= 0)
        //the index has not changed, but the page has changed
        try {
          await this.activePage?.onEnter?.(this._activeTabIndex, this.activePage);

          await this.activePage?.onAfterEnter?.(this._activeTabIndex, this.activePage);
        } catch (error) {
          if (error instanceof EAbort) {
            this._activeTabIndex = 0;
            await this.activePage?.onEnter?.(this._activeTabIndex, this.activePage);
            await this.activePage?.onAfterEnter?.(this._activeTabIndex, this.activePage);
          } else throw error;
        }
    };

    this.pageRangeTest(i);
    const isActivePage = i == this.activeTabIndex;
    await this.lockUI(true);
    try {
      const page = this.pages[i];
      const canDelete = page.canClose ? await page.canClose?.(i, page) : true;
      if (canDelete) {
        //remove the page
        await page.onDelete?.(i, page);
        this.pages = this.pages.filter(x => x !== page);
        if (!isActivePage) {
          //adjust the index before render, but we are not really changing pages
          //we are just compensating for removing a prior page
          if (i < this.activeTabIndex) this._activeTabIndex--;
        } else {
          //if we are removing the active page, if we were the last page decrement the index.
          if (this._activeTabIndex >= this.pageCount) this._activeTabIndex = this.pageCount - 1;
        }
        const eventOptions: PageControlDeleteTabEventData = {
          deletedPage: page,
          deletedIndex: i,
          newIndex: this._activeTabIndex
        };
        await this.events?.onDeleteTab?.(eventOptions);
        if (eventOptions.newIndex !== this._activeTabIndex) {
          if (eventOptions.newIndex >= 0 && eventOptions.newIndex < this.pageCount)
            this._activeTabIndex = eventOptions.newIndex;
        }
        await page.dispose?.(i, page);
        await reValidatePage();

        return true;
      }
      return false;
    } finally {
      await this.lockUI(false);
    }
  }
}
