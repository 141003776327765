// eslint-disable-next-line import/named
// noinspection HtmlUnknownAttribute
import { allowPageControlTabChange, isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import { buttonsSaveCancel } from '../../../webmodule-common/other/ui/modal-confirmation';
import { constructAsync } from '../../../webmodule-common/other/async-constructor';
import { customElement } from 'lit/decorators.js';
import { DataEntryPageControlView, ModalViewBase } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { EventSnippet, EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import { getApiFactory } from '../../api/api-injector';
import { html, TemplateResult } from 'lit';
import {
  MenuIconOption,
  PageControl,
  PageControlOptions,
  PageManager
} from '../../../webmodule-common/other/ui/page-control';
import { PaymentProfileApi } from '../../api/payment-profile-api';
import { PaymentProfileContainer, PaymentProfileContainerManager } from '../data/payment-profile-container-manager';
import { PaymentProfileDetailView } from './payment-profile-detail-view';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { validId } from '../../../webmodule-common/other/ui/string-helper-functions';

export interface PaymentProfileViewOptions {
  paymentProfile: PaymentProfileContainer;
  title: EventSnippet;
}

// write me a function

@customElement('wm-paymentprofiledataentryview')
export class PaymentProfileDataEntryView extends DataEntryPageControlView {
  paymentProfileApi: PaymentProfileApi = getApiFactory().paymentProfile();
  paymentProfile: PaymentProfileContainer;
  paymentProfileManager: PaymentProfileContainerManager;
  protected readonly eventTitle: EventSnippet;
  protected detailView: PaymentProfileDetailView | null = null;

  constructor(options: PaymentProfileViewOptions) {
    super();
    this.eventTitle = options.title;

    this.paymentProfile = options.paymentProfile;
    this.paymentProfileManager = this.paymentProfileContainerManagerFactory();
  }

  /**
   * inherited;
   * @returns
   */
  public async afterConstruction() {
    await this.paymentProfileManager.needsPaymentProfile();
    this.detailView = await this.paymentProfileDetailViewFactory();

    //this will create the pagecontrol
    await super.afterConstruction();
    this.buildPaymentProfileActionMenu();
  }

  createPageControl(): PageControl {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      const pages: PageManager[] = [];
      if (this.detailView) pages.push(this.detailView.createPageManager());
      return pages;
    };
    const options: PageControlOptions = {
      defaultTabIndex: 0,
      pageInitializer: () => getInitialPageManagers()
    };
    return new PageControl(options);
  }

  /**
   * inherited;
   * @returns
   */
  public internalDataChanged(): boolean {
    return this.paymentProfileManager.changed();
  }

  /**
   * inherited;
   * @returns
   */
  public isDataReadonly(): boolean {
    return this.paymentProfileManager.isReadonly();
  }

  /**
   * inherited;
   * @returns
   */
  public async prepareForSave(): Promise<void> {
    if (this.isDataReadonly()) return;

    this.detailView?.prepareForSave();
  }

  /**
   * inherited;
   * @returns
   */
  public getTitle(): Snippet {
    return this.eventTitle();
  }

  /**
   * inherited;
   * @returns
   */
  async allowPageSwitch(): Promise<boolean> {
    return await allowPageControlTabChange(this.getAutoSavePromptOptions());
  }

  public async saveAndClose(): Promise<boolean> {
    if (this.isDataReadonly()) return false;

    const result = await this.performAutoSave();
    if (result) {
      return this.tryClose();
    }
    return false;
  }

  /**
   * inherited;
   * @returns
   */
  protected bodyTemplate(): EventTemplate {
    this.buildPaymentProfileActionMenu();
    return super.bodyTemplate();
  }

  /**
   * inherited;
   * @returns
   */
  protected async internalSaveData(): Promise<boolean> {
    return await this.paymentProfileManager.save(isAutoSaving());
  }

  protected isNew(): boolean {
    return !validId(this.paymentProfileManager.container.paymentProfileId);
  }

  /**
   * inherited;
   * @returns
   */
  protected getValidationErrors() {
    const errors = this.detailView?.getValidationErrors() ?? [];
    return errors;
  }

  protected async paymentProfileDetailViewFactory() {
    return await constructAsync(
      new PaymentProfileDetailView(this, {
        paymentProfileManager: this.paymentProfileManager
      })
    );
  }

  protected paymentProfileContainerManagerFactory(): PaymentProfileContainerManager {
    return new PaymentProfileContainerManager(this.paymentProfile, this.eventTitle, this.paymentProfileApi);
  }

  private buildPaymentProfileActionMenu() {
    const menuIcons: MenuIconOption[] = [];
    if (!this._pageControl) return;

    const save = {
      event: async () => await this.saveAndClose(),
      caption: () => tlang`Save %%payment-profile%%`
    };

    if (!isAutoSaving()) menuIcons.push(save);

    this.pageControl.setMenuIcons(menuIcons);
  }
}

export class PaymentProfileView extends ModalViewBase {
  view: PaymentProfileDataEntryView | null = null;
  options: PaymentProfileViewOptions;

  constructor(options: PaymentProfileViewOptions) {
    super();
    this.options = options;
  }

  /**
   * inherited
   * @returns
   */
  protected get modalSize() {
    return 'modal-lg';
  }

  /**
   * inherited
   * @returns
   */
  async canClose(): Promise<boolean> {
    return (await this.view?.canClose()) ?? true;
  }

  async afterConstruction(): Promise<void> {
    this.view = await constructAsync(this.createView());
  }

  /**
   * inherited
   * @returns
   */
  protected getTitle(): Snippet {
    return this.view?.getTitle() ?? '';
  }

  /**
   * inherited
   * @returns
   */
  protected footerTemplate(): TemplateResult | null {
    const buttons = buttonsSaveCancel();
    return this.createConfirmCancelButtons(buttons.ok, () => this.view?.saveAndClose(), buttons.cancel);
  }

  protected createView(): PaymentProfileDataEntryView {
    return new PaymentProfileDataEntryView(this.options);
  }

  protected bodyTemplate(): EventTemplate {
    return html`${this.view?.ui}`;
  }

  protected renderCloseButtonTemplate(): boolean {
    return false;
  }
}
