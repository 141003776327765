import '../../../../webmodule-common/other/ui/maps/google-map.js';
import '../../../../webmodule-common/other/ui/maps/google-place-autocomplete.js';
import { AddressEditor } from '../../../../webmodule-common/other/ui/address-editor.js';
import { cache } from '../../cache-impl/cache-registry.js';
import { ClientDetailView, ClientDetailViewOptions } from '../../../clients/views/client-detail-view.js';
import { ClientState } from '../../../api/dealer-api-interface-client.js';
import { customElement } from 'lit/decorators.js';
import { DataCacheGeneric } from '../../../../webmodule-common/cache/generic-data-cache.js';
import { DataEntryPageControlView } from '../../../../webmodule-common/other/ui/data-entry-screen-base.js';
import { DevelopmentError } from '../../../../webmodule-common/other/development-error.js';
import { EventTemplate } from '../../../../webmodule-common/other/ui/events.js';
import { FieldType } from '../../../../webmodule-common/other/ui/databinding/data-tracker.js';
import { FormInputAssistant } from '../../../../webmodule-common/other/ui/templateresult/form-input-assistant.js';
import { FranchiseeClientContainerManager } from '../data/franchisee-client-container.js';
import { getApiFactory } from '../../../api/api-injector.js';
import { html } from 'lit';
import { PaymentProfileApi } from '../../../api/payment-profile-api.js';
import { PaymentProfilePicker } from '../../../franchisee/view/payment-profile-picker.js';
import { state } from '@lit/reactive-element/decorators.js';
import { tlang } from '../../../../webmodule-common/other/language/lang.js';

@customElement('wm-franchiseeclientdetailview')
export class FranchiseeClientDetailView extends ClientDetailView {
  paymentProfileApi: PaymentProfileApi = getApiFactory().paymentProfile();
  paymentProfileCache: DataCacheGeneric = cache().paymentProfile;

  @state()
  paymentProfileDisplay = '';

  constructor(owner: DataEntryPageControlView, options: ClientDetailViewOptions) {
    super(owner, options);

    if (!(options.clientContainerManager instanceof FranchiseeClientContainerManager))
      throw new DevelopmentError('FranchiseeClientDetailView only supports FranchiseeClientContainerManager');

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.client),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField(
      'paymentProfileId',
      FieldType.string,
      true,
      undefined,
      () => this.franchiseeClientManager.franchiseeClient
    );
  }

  protected get franchiseeClientManager(): FranchiseeClientContainerManager {
    return this.clientContainerManager as FranchiseeClientContainerManager;
  }

  public async loadOrRefresh(): Promise<void> {
    await super.loadOrRefresh();
    await this.loadPickerDisplaysFromCache();
    this.requestUpdate();
  }

  public getValidationErrors(): string[] {
    const errors = super.getValidationErrors();

    if (!this.franchiseeClientManager.hasPaymentProfile()) errors.push(tlang`Please provide a %%payment-profile%%`);

    return errors;
  }

  protected async loadPickerDisplaysFromCache() {
    if (!this.franchiseeClientManager.hasPaymentProfile()) {
      this.paymentProfileDisplay = '';
    } else {
      const paymentProfile = await this.paymentProfileCache.get(
        this.franchiseeClientManager.franchiseeClient.paymentProfileId
      );
      this.paymentProfileDisplay = paymentProfile?.displayValue ?? '';
    }
  }

  protected bodyTemplate(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.clientContainerManager.isReadonly());
    const readonly = this.clientContainerManager.isReadonly();

    const clientTypePicker = () => {
      const ctPick = async (e: PointerEvent) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        if (readonly) return;

        await this.SelectPaymentProfile();
      };

      return html`
        ${forms.textHidden('paymentProfileId')}

        <webmodule-input
          class="webmodule-control-left-label"
          type="text"
          label="${tlang`%%payment-profile%%`}:"
          ?readonly=${readonly}
          ?filled=${readonly}
          value=${this.paymentProfileDisplay}
          ?required=${true}
          size="small"
          @click=${ctPick}
        >
          <webmodule-icon slot="suffix" name="fas-ellipsis" library="fa"></webmodule-icon>
        </webmodule-input>
      `;
    };

    return html`
      <form class="frm-client-details form-two-col">
        <div class="row">
          <div>
            <h2>${tlang`%%client%% Details`}</h2>
            ${forms.textRequired('name', tlang`Name`, 100)} ${forms.text('businessPhoneNumber', tlang`Phone`, 30)}
            ${clientTypePicker()} ${forms.radioGroup('state', ClientState, tlang`Status`)}
          </div>

          <div>
            <h2>${tlang`Contact Details`}</h2>
            ${this.primaryContactTemplate(forms)}
          </div>
        </div>

        <wm-addresseditor
          @wm-ae-changed=${(e: Event) => this.physicalAddressModified(e.currentTarget as AddressEditor)}
          id="physicalAddressEditor"
          .address=${this.client.physicalAddress}
          .shippingNotes=${this.client.shippingNotes ?? undefined}
          .title=${tlang`Physical Address`}
          .readonly=${readonly}
          .isDefaultShippingVisible=${true}
          .isDefaultShipping=${this.client.shipToPhysicalAddress}
        ></wm-addresseditor>
      </form>
    `;
  }

  private async SelectPaymentProfile() {
    const selectedProfile = await PaymentProfilePicker(this.paymentProfileApi);

    if (selectedProfile) {
      this.dataBinding.setValue('paymentProfileId', selectedProfile.id);
      this.paymentProfileDisplay = selectedProfile.name;
      this.requestUpdate();
    }
  }
}
