// eslint-disable-next-line import/named
import { DataBinding } from '../databinding/databinding';
import { DataTracker } from '../databinding/data-tracker';
import { FormInputSelectValue } from '../../FormInputView';
import { html, TemplateResult } from 'lit';
import { map } from 'lit/directives/map.js';
import { WebmoduleChangeEvent } from '../../../components/src/webmodule-components';
import { when } from 'lit/directives/when.js';

export function formSelect(
  fieldName: string,
  placeHolder: string,
  title: string | undefined,
  options: string | FormInputSelectValue[],
  dataBinding: DataBinding,
  dataTracker: DataTracker,
  readOnly = false,
  required = false,
  onChangeEvent?: (e: WebmoduleChangeEvent) => Promise<void>
): TemplateResult {
  const internalOptions = options ?? [];

  const optionsArray: FormInputSelectValue[] =
    typeof internalOptions === 'string' ? JSON.parse(internalOptions) : internalOptions;

  const _options: HTMLOptionElement[] = [];

  optionsArray.forEach(x => _options.push(new Option(x.text, x.value)));

  return html` <webmodule-select
    class="webmodule-control-left-label"
    id=${dataBinding.field(fieldName)}
    placeholder=${placeHolder}
    ?disabled=${readOnly}
    ?required=${required}
    value=${dataTracker.getObjectValue(fieldName)}
    size="small"
    @webmodule-change="${onChangeEvent}"
    hoist
  >
    ${when(title, () => html` <span slot="label"> ${title}: </span> `)}
    ${map(optionsArray, x => html`<webmodule-option value="${x.value}">${x.text}</webmodule-option>`)}
  </webmodule-select>`;
}
